.mobix-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    width: 60%;
}

.main-text {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 40px;
    display: block;
}

.list-number {
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 600;
}

.list-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.3em;
}

.list-text {
    font-size: 1.5rem;
    line-height: 28px;
    margin-left: 0.9rem;
}

.badge-image-container {
    display: flex;
    align-items: center;
}

.link-buttons {
    display: inline-block;
    border-radius: 13px;
    width: 237px;
}

.link-buttons:first-child{
    margin-left: 0.3em;
}

.link-images {
    border-radius: 13px;
    width: 250px;
    height: 65px;
}

.link-images-google{
    max-width: 100%;
}