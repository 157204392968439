.link-buttons {
    display: inline-block;
    border-radius: 13px;
    max-width: 237px;
}

.link-images {
    border-radius: 13px;
    height: 71px;
    max-width: 100%;
}