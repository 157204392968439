@import url(https://fonts.googleapis.com/css?family=Barlow);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    width: 100%;
    font-family: Barlow,sans-serif !important;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), linear-gradient(234.38deg, #0073c4 4.84%, #00c185 93%), #ffffff;
    background-size: cover;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

.container {
    min-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.child {
    width: 50%;
    display: flex;
    align-items: center;
}

.image{
    margin-bottom: 3.5em;
}

h1.main-header {
    color: white
}

a.main-header{
    text-decoration: none;
}

h1.main-header:hover{
    color: darkgrey !important;
}







.MobixCart_card__2mTMT {
    width: 100%;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    display: flex;
    flex-direction: row;
}


.MobixContent_mobix-content-container__2Q-dY {
    display: flex;
    flex-direction: column;
    margin-left: 2em;
    width: 60%;
}

.MobixContent_main-text__2lMQq {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 40px;
    display: block;
}

.MobixContent_list-number__1Owuy {
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: 600;
}

.MobixContent_list-container___v8Ij {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.3em;
}

.MobixContent_list-text__2VZt8 {
    font-size: 1.5rem;
    line-height: 28px;
    margin-left: 0.9rem;
}

.MobixContent_badge-image-container__1Ir1e {
    display: flex;
    align-items: center;
}

.MobixContent_link-buttons__2ZdFe {
    display: inline-block;
    border-radius: 13px;
    width: 237px;
}

.MobixContent_link-buttons__2ZdFe:first-child{
    margin-left: 0.3em;
}

.MobixContent_link-images__1aIdo {
    border-radius: 13px;
    width: 250px;
    height: 65px;
}

.MobixContent_link-images-google__3ETBi{
    max-width: 100%;
}
.AppleStoreButton_link-buttons__2h_hl {
    display: inline-block;
    border-radius: 13px;
    max-width: 237px;
}

.AppleStoreButton_link-images__3kd2Z {
    border-radius: 13px;
    height: 71px;
    max-width: 100%;
}
.GooglePlayButton_link-buttons__2S7ep {
    display: inline-block;
    border-radius: 13px;
    width: 265px;
}

.GooglePlayButton_link-images-google__2ejHH{
    max-width: 100%;
}
.QrCodeModal_qr-code-container__L_isn {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.QrCodeModal_qr-code-border__1Wch0{
    border: 3px solid #000000;
    border-radius: 16px;
    padding: 1.1em;
    max-width: 50%;
}

h1 {
    font-size: 2.4em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-weight: 700;
}

h4 {
    color: darkslategrey;
    font-weight: 600;
}

em {
    color: rgba(0, 0, 0, 0.7);
}

blockquote {
    padding-left: 0.5em;
}

section:first-child {
    margin-bottom: 2em;
}

section:not(:first-child) {
    margin-bottom: 6em;
}

blockquote {
    font-family: "Courier New", Courier, monospace;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.site-header {
    border-top: none;
    border-bottom: none;
}

.content-wrapper {
    max-width: calc(800px - (30px * 2));
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1em;
    padding: 2.5em 30px 2em;
    color: white;
}

.image {
    margin-bottom: 3em;
}

.loading {
    text-align: center;
    margin-top: 8em;
}

.wrapper {
    display: flex;
    flex-direction: row;
    width: 99%;
}


.select-wrapper {
    margin-top: 5em;
    width: 6.9%;
}

.white-text-color{
    color: white;
}


.select-options-navbar-main-color div {
    color: white !important;
}

.select-options-navbar-main-color::before {
    border-color: white !important;

}

.select-options-navbar-main-color::after {
    border-color: white !important;

}

.select-options-navbar-main-color:not(.Mui-disabled):hover::before {
    border-color: white !important;

}

.select-options-navbar-main-color svg {
    fill: white !important;
}




.error {
    text-align: center;
    color: #db8686;
    margin-top: 8em;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;
    background-color: #696970;
    border: 1px solid #e8e8e8;
    border-radius: 3px;

}

pre > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
}

code {
    font-size: 15px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    padding: 0.09em;
    background-color: #696970;
}

em {
    color: #9ed2ff;
    font-size: 1.1em;
}

.site-footer {
    font-size: 0.7em;
}

#punchline {
    padding: 0em 0 1em;
    font-weight: 300;
    font-size: 1.2em;
}

.award {
    color: brown;
    font-weight: 900;
    border-radius: 4px;
}

.cardholder {
    display: flex;
    flex-wrap: wrap;
}


.card {
    min-width: 40%;
    max-width: 100%;
    justify-content: space-between;
    padding: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
    flex: 1 1;
}

.card p {
    text-align: right;
    font-size: 2em;
}

.card h2 {
    margin-bottom: 0;
}

.card .card-subtitle {
    min-height: 1em;
    font-size: 0.7em;
    font-weight: 300;
}

.card-vendor {
    border-radius: 10px;
    background-color: #ffedc9;
}

.card-vendor h2 {
    color: black;
}

.card-award-color-1 {
    background-color: #800026;
}

.card-award-text-color-1 {
    color: #ffffff;
}

.card-award-color-2 {
    background-color: #bd0026;
}

.card-award-text-color-2 {
    color: #ffffff;
}

.card-award-color-3 {
    background-color: #e31a1c;
}

.card-award-text-color-3 {
    color: #ffffff;
}

.card-award-color-4 {
    background-color: #fc4e2a;
}

.card-award-text-color-4 {
    color: #ffffff;
}

.card-award-color-5 {
    background-color: #fd8d3c;
}

.card-award-text-color-5 {
    color: #000000;
}

.card-award-color-6 {
    background-color: #feb24c;
}

.card-award-text-color-6 {
    color: #000000;
}

.card-award-color-7 {
    background-color: #fed976;
}

.card-award-text-color-7 {
    color: #000000;
}

.card-award-color-8 {
    background-color: #ffeda0;
}

.card-award-text-color-8 {
    color: #000000;
}

.card-award-color-9 {
    background-color: #ffeda0;
}

.card-award-text-color-9 {
    color: #000000;
}

.card-award-color-10 {
    background-color: #ffeda0;
}

.card-award-text-color-10 {
    color: #000000;
}

.card-award-color-11 {
    background-color: #ffeda0;
}

.card-award-text-color-11 {
    color: #000000;
}

.card-award-color-12 {
    background-color: #ffeda0;
}

.card-award-text-color-12 {
    color: #000000;
}

.card-award-color-13 {
    background-color: #ffeda0;
}

.card-award-color-14 {
    background-color: #ffeda0;
}

.card-award-color-15 {
    background-color: #ffeda0;
}

.card-vendor:hover {
    background-color: grey;
}

.card-vendor-award {
    border-radius: 10px;
}

.card-vendor-award h2 {
    color: black;
}

.card-vendor-award p {
    color: black;
}

.award-icon {
    display: inline;
    height: 1em;
    padding-top: 0;
    padding-bottom: 0;
}

#about_score .award-icon {
    height: 3em;
}

.score {
    margin-bottom: 1em;
}

section {
    display: block;
}

#score {
    margin: 0.5em;
    display: inline-block;
}

.score-empty {
    opacity: 0.5;
}

#copyembed {
    text-decoration: underline;
    cursor: pointer;
}

#copyembed:active {
    color: orange;
}

.customization-switch {
    height: 2em;
    display: inline;
    padding: 1em;
}

.card-vendor-award:hover {
    background-color: grey;
}

#vendor-btn-contribute {
    background-image: url("/assets/img/pencil-outline.svg");
    background-size: contain;
    height: 1.2em;
    margin: 0.4em;
    padding: 0.4em;
    padding-left: 2.4em;
    background-repeat: no-repeat;
}

#vendor-btn-contribute:hover {
    font-weight: bold;
}

#vendor-btn-feedback {
    background-image: url("/assets/img/pencil-outline.svg");
    background-size: contain;
    height: 1.2em;
    margin: 0.4em;
    padding: 0.4em;
    padding-left: 2.4em;
    background-repeat: no-repeat;
}

#vendor-btn-feedback:hover {
    font-weight: bold;
}

a, a:visited {
    color: #F57C00;
}

a:hover {
    text-decoration: none;
}

.page-link#press {
    color: rgba(0, 0, 0, 0.5);
}

#vendor-menu {
    margin-top: 1em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}

#vendor-menu nav {
    padding: 0.2em;
}

#vendor-menu nav a {
    padding: 1em 2em;
}

#vendor-menu nav a:hover {
    background-color: lightgrey;
    color: grey;
}

main.page-content {
    padding-top: 2em;
}

img {
    padding-top: 1em;
    padding-bottom: 1em;
    max-height: 25em;
    display: block;
    margin: 0 auto;
}

.img-block {
    text-align: center;
}

.img-block img {
    display: inline-block;
}

.img-block img:last-of-type {
    display: block;
}

.img-block figure {
    display: inline-block;
    vertical-align: top;
}

figure > img {
    display: block;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

@media only screen and (min-width: 601px) {
    .awarded-vendor-link, .vendor-link {
        display: none;
    }

    #vendor-menu {
        flex-direction: row;
    }
}

.site-nav {
    z-index: 1;
}

.circular-progress {
    display: block;
    width: 80px !important;
    height: 80px !important;
    margin: 0 auto;
}

.box {
    display: flex;
    margin-top: 15em;
}

.dont-break-out, a {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -webkit-hyphens: auto;
    hyphens: auto;
}

.caution-box {
    color: #D8000C;
    background-color: #FFD2D2;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 10px;
}

#vendor-head {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

#user-solution-section-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

#play-store-badge img {
    height: 2.8em;
    display: inline;
    padding: 0.4em 0.3em;
}
