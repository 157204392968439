@import url('https://fonts.googleapis.com/css?family=Barlow');



body {
    width: 100%;
    font-family: Barlow,sans-serif !important;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), linear-gradient(234.38deg, #0073c4 4.84%, #00c185 93%), #ffffff;
    background-size: cover;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

.container {
    min-height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.child {
    width: 50%;
    display: flex;
    align-items: center;
}

.image{
    margin-bottom: 3.5em;
}

h1.main-header {
    color: white
}

a.main-header{
    text-decoration: none;
}

h1.main-header:hover{
    color: darkgrey !important;
}






