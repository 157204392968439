.qr-code-container {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-code-border{
    border: 3px solid #000000;
    border-radius: 16px;
    padding: 1.1em;
    max-width: 50%;
}